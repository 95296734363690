<template>
  <div class="auth sign-in">

    <form @submit="sign_in" class="form" method="post">

      <div class="form-wrap">
        <label for="first_name">E-Mail</label>
        <input v-model="user.email" type="text" class="form-input" id="first_name">
      </div>

      <div class="form-wrap">
        <label for="last_name">Passwort</label>
        <input v-model="user.password" type="password" class="form-input" id="last_name">
      </div>

      <p style="margin: 30px 0 0 0">
        <input type="submit" value="Jetzt einloggen ›" class="button button-red button-round button-100">
      </p>

    </form>

  </div>
</template>

<script>
export default {
  name: 'auth_sign_in',
  data () {
    return {
      user: {
        email: "",
        password: ""
      }
    }
  },
  methods: {
    get_data() {
      this.$http.get(process.env.VUE_APP_BASE_API+'/user', { headers: { Authorization: this.$store.getters.get_token } })
      .then(() => {
        this.$router.push('/admin/appointments');
      })
    },
    sign_in(e) {
      e.preventDefault();

      this.$http.post(process.env.VUE_APP_BASE_API+'/auth/sign_in', {
        email: this.user.email,
        password: this.user.password
      }, { headers: {} })
      .then(response => {
        localStorage.setItem('nsc_token', response.headers.authorization);
        this.$store.commit('add_token', response.headers.authorization);
        this.$router.push('/admin/dashboard');
      })
      .catch(error => {
        error.response.data.errors.forEach(value => {
          this.$notify({
            title: value,
            type: "error"
          });
        });
      });
    }
  },
  mounted () {
    this.get_data();
  }
}
</script>
